import HomeBanner from "../components/HomeBanner";
import Campaign from "../components/Campaign";
import AboutSection from "../components/AboutSection";
import CategorySection from "../components/CategorySection";
import Testimonial from "../components/Testimonial";
const Home = () => {
  return (
    <>
      <HomeBanner />
      <Campaign />
      <AboutSection />
      <CategorySection />
      <Testimonial />
    </>
  );
};
export default Home;
