const CommingSoon = () => {
  return (
    <>
      <section className="inner-banner-sec coming-soon-sec py_100">
        <div className="container">
          <div className="inner-banner-sec-content text-center">
            <h1>
              <span className="grn">Coming</span> soon.
            </h1>
            <h5>This website is under construction.</h5>
          </div>
        </div>
      </section>
    </>
  );
};
export default CommingSoon;
